<style lang="scss">
.o-add-new-test{
    .c-btn{
        #app &{
            min-width: 200px;
        }
    }
    .v-stepper--vertical .v-stepper__wrapper{
          padding: 20px 30px;
    }
    .v-stepper--vertical {
      padding-bottom: initial;
    }
    .v-stepper--vertical .v-stepper__wrapper{
      #app &{
        padding-bottom: initial;
      }
    }
    .v-select__selections .theme--light.v-chip:not(.v-chip--active){
      #app &{
        border-radius: 5px !important;
        height: 25px;
        margin: auto !important;
        margin-right: 8px !important;
      }
    }
    .v-select__selections{
      #app &{
        height: 100%;
      }
    }
}

.o-modal-info-requerida{
  .v-list-item__icon{
    margin-right: auto;
  }
  .c-bullet{
    font-size: 16pt;
    margin-left: 6px;
  }
}
</style>
<!-- eslint-disable -->
<template>
  <div class="o-add-new-test">
      <div v-if="$store.state.seeTable">
      <!--<div v-if="seeTable">-->
        <transition name="slide-fade">
          <ensayos :setSee="setSee" ref="ensayos" />
        </transition>
      </div>
      <div v-else>
        <transition name="slide-fade">
          <v-card class="bg--light__gray">
            <!-- <v-toolbar
              color="grey lighten-4"
              height="100px"
               class="c-view-title"
              > -->
            <!--<toogleNavMenu ref="toogleNavMenu" />-->
            <!-- <div class="c-view-title-text col-lg-4 col-sm-4 col-4">
                <v-toolbar-title>
                    <v-text-field
                      label="Nombre del ensayo"
                      class="c-view-title__input inline-block"
                      v-model="form_data.data_ensayo.nombre"
                      :rules="rules.name"
                      required
                    ></v-text-field>
                    <v-chip
                    x-small
                    label
                    color="green darken-1"
                    class="c-tag c-tag--active"
                    dark
                    >
                      Active
                    </v-chip>
                    <v-checkbox
                      v-model="form_data.steriplex"
                      class="inline-block"
                      label="Steriplex"
                      @change="selectedTipores($event)"
                    ></v-checkbox>
            </div> -->
                <!-- <v-spacer></v-spacer>
                <v-chip
                  class="ma-2 btn-secondary c-btn c-btn--secondary"
                  color="indigo darken-3"
                  outlined
                  @click="cancelForm()"
                >
                  <span class="entities mr-2">&times;</span>
                  Cancelar
                </v-chip>
                <v-chip
                  class="ma-2 c-btn c-btn--primary"
                  color="indigo darken-3"
                  :disabled="isSaveDisabled"
                  @click="step3()"
                  outlined
                >
                  <span class="entities mr-2">&check;</span>
                  Guardar
                </v-chip>
            </v-toolbar> -->
            <v-card-text class="bg--light__gray">
              <v-stepper v-model="step" vertical class="bg--light__gray">
                  <!-- PASO 1 -->
                 <!--  <v-stepper-step :complete="form_data.step > 1" step="1">
                      Agrega un ensayo de laboratorio
                      <small> </small>
                  </v-stepper-step> -->

                  <v-stepper-content step="1" class="bg--light__gray">
                      <formInformation ref="formInfoEns"></formInformation>
                      <!-- <v-btn color="primary"
                      class="c-btn c-btn--primary"
                      @click="step1()">Continuar</v-btn> -->
                  <!-- </v-stepper-content> -->
                  <!-- FIN PASO 1 -->

                  <!-- PASO 2 -->
                  <!-- <v-stepper-step :complete="form_data.step > 2" step="2"> -->
                      <!-- Detalle de la técnica -->
                      <!-- <small> Agrega o remueve matrices </small> -->
                  <!-- </v-stepper-step> -->

                  <!-- <v-stepper-content step="2"> -->
                      <formDetalle ref="formInfoDetalle" @myEvent="step2()"></formDetalle>

                      <!-- <v-btn color="primary"
                      class="c-btn c-btn--primary"
                      @click="step2()">Continuar</v-btn>
                      <v-btn text
                       class="c-btn c-btn--secondary"
                       @click="setStep(1)">Atrás</v-btn> -->
                  <!-- </v-stepper-content> -->
                  <!-- FIN PASO 2 -->
                  <formUmbrales  ref="formInfoUmbrales"></formUmbrales>
                  <!-- <controlesUmbral v-if="form_data.data_ensayo.tipoRes != 9 && form_data.data_ensayo.tipoRes != null" ref="controlUmbrales"></controlesUmbral> -->
                  <!-- PASO 3 -->
                  <!-- <v-stepper-step :complete="form_data.step > 3" step="3"> -->
                      <!-- Matrices y umbrales -->
                      <!-- <small> Agrega o desactiva patogenos de este ensayo </small> -->

                          <!--color="primary"
                          class="c-btn c-btn--primary"-->

                  <!-- </v-stepper-step> -->

                  <!-- <v-stepper-content step="3"> -->
                     

                      <div class="row">
                        <v-col cols="12">
                          <div class="ml-8 d-flex ">
                            <v-btn
                                class="mr-5 c-btn c-btn--blue bold"
                                outlined
                                @click="step3()"
                                :loading="loadingF"
                                :disabled="loadingF">
                                {{ $t('ensayos.guardar_ensayo') }}
                            </v-btn>
                            <v-btn
                              class="c-btn"
                              color="#007AFF"
                              outlined
                              @click="cancelForm(false)">
                                {{ $t('ensayos.cancelar') }}
                            </v-btn>
                         </div>
                        </v-col>
                      </div>
                  </v-stepper-content>
                  <!-- FIN PASO 3 -->

                  <!-- OVERLAY -->
                  <v-overlay opacity="1" z-index="7" :value="overlay">
                    <div class="modal-loader">
                      <div class="custom-loader"></div>
                      <h2 class="mt-4 bold white">{{$t('menu.cargando')}}...</h2>
                    </div>
                  </v-overlay>

              </v-stepper>
            </v-card-text>
          </v-card>
        </transition>
      </div>

        <v-snackbar
        v-model="error.error"
        :timeout="2000"
        :top="true"
        :right="true"
        >
          {{ error.txt }}
          <v-btn
              color="#fff"
              text
              @click="error.error = false"
          >
              {{ $t('menu.Dismiss') }}
          </v-btn>
        </v-snackbar>

        <v-snackbar
        v-model="snack.activo"
        :timeout="2000"
        :top="true"
        :right="true"
        >
          {{ snack.txt }}
          <v-btn
              color="#007AFF"
              text
              @click="snack.activo = false"
          >
            {{ $t('menu.Dismiss') }}
          </v-btn>
        </v-snackbar>

        <!-- dialod -->
    <v-dialog v-model="dialogensayo" max-width="500" persistent>
        <v-card>
            <v-card-title class="headline">
              Faltan campos
            </v-card-title>

            <v-card-text>
                Existe algunos ensayos con las mismas caracteristicas favor de revisar
                o crear el ensayo de todas formas.

                <v-list>
                  <v-list-group
                    v-for="item in ensayoduplicados"
                    :key="item.id"
                    prepend-icon="mdi-ticket"
                    no-action
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.ensa_informe"></v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.metodologia"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-list>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="green darken-1" text @click="aceptarModal">
                    Aceptar
                </v-btn>

                <v-btn color="green darken-1" text @click="crearensayo">
                    Crear Ensayo nuevo
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogreq"
      max-width="350"
    >
      <v-card class="o-modal-info-requerida">
        <v-card-title class="text-h5">
          {{$t('ensayos.Informationrequired')}}
        </v-card-title>
        <v-card-text>
          <v-list disabled>
            <!-- <v-subheader>REPORTS</v-subheader> -->
            <v-list-item-group
              v-model="selectedItem"
            >
              <v-list-item
                v-for="(item, i) in selectedItem"
                :key="i"
                style="text-align:left"
              >
                <v-list-item-icon>
                  <!--
                  <v-icon small>mdi-information-outline</v-icon>
                  -->
                  <span class="c-bullet">&bull;</span>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.campo"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <!--
          <v-spacer></v-spacer>
          -->
          <v-btn
            class="m-auto c-btn c-btn--blue bold"
            outlined
            @click="dialogreq = false"
          >
            {{$t('analisis.aceptar')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog warning -->
    <v-dialog
      v-model="dialogstop"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{$t('ensayos.warning')}}
        </v-card-title>
        <v-card-text>
          <v-list disabled>
            <!-- <v-subheader>REPORTS</v-subheader> -->
            <v-list-item-group
              v-model="warningForm"
            >
              <v-list-item
                v-for="(item, i) in warningForm"
                :key="i"
                style="text-align:left"
              >
                <v-list-item-icon>
                  <v-icon small>mdi-information-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.campo"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="c-btn"
            color="primary"
            outlined
            @click="dialogstop = false"
          >
            {{$t('ensayos.cancelar')}}
          </v-btn>
          <v-btn
            class="c-btn"
            color="primary"
            @click="continuarGuardado()"
          >
          {{$t('continue')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState, mapMutations, mapActions } from 'vuex';
import Service from '../services/apis';
import i18n from '../plugins/il8n';
/* eslint-disable */
export default {
  name: 'Ensayos',
  components: {
    ensayos: () => import('./Ensayos/Ensayos'),
    formInformation: () => import('./Ensayos/Informacion'),
    formDetalle: () => import('./Ensayos/Detalle'),
    formUmbrales: () => import('./Ensayos/Umbrales'),
    // controlesUmbral: () => import('./Ensayos/controlesUmbral'),

    
    // toogleNavMenu: () => import('./toogleNavMenu'),
  },
  computed: {
    ...mapState('Ensayos', ['data_ensayo', 'bool_form_steps', 'form_data', 'matrizunidad', 'userInfo', 'rangos']),
    step() {
      return this.form_data.step;
    },
    isSaveDisabled() {
      return (this.form_data.step !== 3);
      // return true;
    },
  },
  data() {
    return {
      dialogreq: false,
      dialogensayo: false,
      selectedItem: [],
      ensayoduplicados: [],
      steriplex: false,
      seeTable: true,
      loadingF: false,
      dialogstop: false,
      warningForm: [],
      show: false,
      error: {
        error: false,
        txt: null,
      },
      snack: {
        activo: false, txt: null, color: 'red', error: false,
      },
      response: null, 
      selected: [],
      overlay: false,
      rules: {
        name: [
          (v) => !!v || 'El nombre del ensayo es requerido',
        ],
      },
      formSend: null,
    };
  },
  methods: {
    ...mapMutations('Ensayos', ['setValidDataEnsayo', 'setFormStep', 'setStep', 'tipoEnsasel', 'clearAllDataForm', 'datosEnsayo', 'datosParametrossel', 'fillObjectForm', 'datosUser']),
    step1() {
      this.setValidDataEnsayo(this.$refs.formInfoEns.validate());
      if (this.form_data.data_ensayo_valid) {
        this.setStep(2);
      }
    },
    ...mapActions('Ensayos', ['actionRangos']),
    step2() {
      // obtenemos lo parametros seleccionados para asignarles su nivel de peligrosidad
      const parametrosSelected = [];
      this.data_ensayo.parametros.forEach((element) => {
        this.form_data.data_ensayo.parametrosid.forEach((element2) => {
          if (element.cod_pat === element2) {
            /* eslint-disable no-param-reassign */
            element.peligrosidadID = 0;
            parametrosSelected.push(element);
          }
        });
      });
      // filtro los que son editados para sacar su idpeligrosidad
      parametrosSelected.forEach((element) => {
        this.form_data.parametrosedit.forEach((element2) => {
          if (element.cod_pat === element2.patogeno_new_cod_pat) {
            element.peligrosidadID = element2.peligrosidadID;
          }
        });
      });
      // // console.log('parametros', parametrosSelected);
      this.datosParametrossel(parametrosSelected);
      // fin de obtencion de parametros
      // if (this.$refs.formInfoDetalle.validate()) {
      /// validaciones
      let numberMatriz = 0;
      let unidadVacio = 0;
      this.form_data.matrices_unidades.forEach((element) => {
        this.matrizunidad.ali = 1;
        if (element.activo === true) {
          numberMatriz += 1;
        }
        if (element.activo === true && element.unidades === '') {
          unidadVacio += 1;
        }
      });
      if (numberMatriz === 0) {
        // this.snack.error = true;
        // this.snack.activo = true;
        // this.snack.color = 'red';
        // this.snack.txt = 'Selecciona una matriz con sus unidades';
        return;
      }
      if (unidadVacio > 0) {
        // this.snack.error = true;
        // this.snack.activo = true;
        // this.snack.color = 'red';
        // this.snack.txt = 'Agregar unidades a la matriz seleccionada';
      }

      // this.setStep(3);
      // }
    },
    parametrosSel() {
      // console.log('ejecuto parametros');
      const parametrosSelected = [];
      this.data_ensayo.parametros.forEach((element) => {
        this.form_data.data_ensayo.parametrosid.forEach((element2) => {
          if (element.cod_pat === element2) {
            /* eslint-disable no-param-reassign */
            element.peligrosidadID = 0;
            parametrosSelected.push(element);
          }
        });
      });
      // filtro los que son editados para sacar su idpeligrosidad
      parametrosSelected.forEach((element) => {
        this.form_data.parametrosedit.forEach((element2) => {
          if (element.cod_pat === element2.patogeno_new_cod_pat) {
            element.peligrosidadID = element2.peligrosidadID;
            element.nivel = element2.nivel;
            element.clasificacionid = element2.clasificacion;
          }
        });
      });
      // console.log('parametros', parametrosSelected);
      this.datosParametrossel(parametrosSelected);
    },
    async step3() {
      console.log('datainfo', this.form_data)

      // return;
      
      this.parametrosSel();
      if (this.$refs.formInfoUmbrales.validate()) {
        this.loadingF = true;
        // // console.log(this.$store.state.Ensayos.userInfo);
        // // console.log('datainfo', this.form_data);
        const formData = JSON.parse(JSON.stringify(this.form_data));
        formData.user = JSON.parse(JSON.stringify(this.$store.state.Ensayos.userInfo));
        // // console.log('formadata', formData);
        // si el tipo de ensayos es dif a count validamosy reestructuramos el romddata
        if (formData.data_ensayo.tipoRes !== 3) {
          formData.data_umbrales = await this.umbralConfig(formData.data_umbrales);
        }
        // // console.log('formadata despues de umbrales', formData);
        /*
        const verifica = await Service.apiToken('POST', 'verifica-ensayo',
         this.$ls.storage.token, this.form_data);
        */
        /* eslint no-lonely-if: "error" */

       
        if (this.form_data.data_ensayo_original) {
          console.log('entra a edit ensayo',formData)

          const validate = await this.validarCampos(formData);
          if (validate.length > 0) {
            console.log('aquilodetiene');
            this.dialogreq = true;
            this.selectedItem = validate;
            this.loadingF = false;
            return;
          }
          
          await Service.apiToken('POST', 'edit-ensayos', this.$ls.storage.token, formData);
          let that = this;

          

          setTimeout(function (){
            // regresamos a inicio
            that.loadingF = false;
            that.cancelForm(false);
          }, 3000);
          
        } else {
          console.log('nuevo ensayos ');
          // validamos ensayo los datos
          this.formSend = formData;
          const validate = await this.validarCampos(formData);
          if (validate.length > 0) {
            this.dialogreq = true;
            this.selectedItem = validate;
            this.loadingF = false;
            return;
          }
         
          //return;
          /// verificamos si no hay ningun umbral mandamos mensaje de confirmacion
          const validaUmbrales = await this.validarUmbrales(formData);
          if(validaUmbrales.length > 0){
            this.dialogstop = true;
            this.warningForm = validaUmbrales;
            this.loadingF = false;
            return;
          }
          

          await Service.apiToken('POST', 'save-ensayos', this.$ls.storage.token, formData);
          setTimeout(this.despuesEnsayo(), 3000);
        }

      }
    },
    async despuesEnsayo(){
      this.loadingF = false;
      this.seeTable = true;
      this.$store.state.seeTable = true;
      this.formSend = null;
      this.cancelForm(false);
      // location. reload();
      this.$router.go(0);

    },
    //VALIDA QUE EXISTA AL MENOS UN CONTROL NEGATIVO Y UNO POSITIVO
    
    //---------------------------------//----------------------//
    validarCampos(formData) {
      
      const camposfaltan = [];
      // const nameEnsayo = formData.data_ensayo.nombre;
      console.log({DATAPRINCIPAL:formData.data_ensayo.dataControl})
      let Datacontrol = formData.data_ensayo.dataControl;
      if(this.form_data.data_ensayo.tipoRes != 9){
        let countNoDecs = 0;
        // for (let i = 0; i < Datacontrol.length; i++) {
        //   if(Datacontrol[i].ln_desc_muestra == ''){
        //     countNoDecs++;
        //   }
        //  console.log({LOSNOCONTROLES:Datacontrol[i].ln_desc_muestra});
          
        //  }
        if(countNoDecs > 0){
          camposfaltan.push({ campo: i18n.t('ensayos.descRequired') });
        }
     
      // if(formData.data_ensayo.dataControl.length <= 0){
      //   camposfaltan.push({ campo: i18n.t('ensayos.NoControles') });
      // }
     
      //  let validatecontrols = Datacontrol.filter((control) => control.id_control == 2);
      //  console.log({DATACONTRORORLL:validatecontrols})

      //  if(validatecontrols.length <= 0){
      //   camposfaltan.push({ campo: i18n.t('ensayos.NoControlesPos') });
      //  }

      //  let validatecontrolsNeg = Datacontrol.filter((control) => control.id_control == 1);
      //  if(validatecontrolsNeg.length <= 0){
      //   camposfaltan.push({ campo: i18n.t('ensayos.NoControlesNeg') });
      //  }
       
        let key = 0;
        if(this.form_data.data_ensayo.tipoRes == 3){

      
        // for (let i = 0; i < Datacontrol.length; i++) {
         
          
        //     if(Datacontrol[i].umbral != undefined){
        //       if( Datacontrol[i].umbral.length <= 0 ){
        //     key++;
        //     }
        //     }else{
        //       if( Datacontrol[i].tb_r_controles_umbrals.length <= 0 ){
        //     key++;

        //     }
        //     }
           
        //  }
      
         if(key >= 1){
          camposfaltan.push({ campo: i18n.t('ensayos.UmbralRequired')  });
         }
        }
      }
      if (formData.data_ensayo.horas < formData.data_ensayo.horas_incub) {
       
        camposfaltan.push({ campo: i18n.t('ensayos.rules_incub') });
      }
      if (!formData.data_ensayo.nombre) {
        console.log('lodetiene1');
        camposfaltan.push({ campo: i18n.t('ensayos.rules_txt0') });
      }
      if (!formData.data_ensayo.descripcion) {
        console.log('lodetiene2');
        camposfaltan.push({ campo: i18n.t('ensayos.rules_txt8') });
      }
      if (formData.data_ensayo.parametrosid.length === 0) {
        console.log('lodetiene3');
        camposfaltan.push({ campo: i18n.t('ensayos.rules_txt5') });
      }
      if (!formData.data_ensayo.tipoRes) {
        console.log('lodetiene4');
        camposfaltan.push({ campo: i18n.t('ensayos.rules_txt6') });
      }
      if (formData.data_ensayo.parametrosCondicion.length === 0
      && formData.data_ensayo.microbiologia === 1) {
        console.log('lodetiene5');
        camposfaltan.push({ campo: i18n.t('ensayos.rules_txt_condicionparam') });
      }
      const specificacion = [];
      formData.data_ensayo.parametrosCondicion.forEach((element) => {
        if (!element.clasificacionid || typeof element.nivel === 'undefined') {
          console.log('lodetiene6');
          specificacion.push(element.nombre);
        }
      });
      if (specificacion.length > 0 && formData.data_ensayo.microbiologia === 1) {
        console.log('lodetiene7');
        camposfaltan.push({ campo: i18n.t('ensayos.rules_txt_condicionparam') });
      }
      /// recorremos los umbrales para preguntar si es count(3) hacer obligatorio los umbrales
      // let umbraAlim = formData.data_umbrales
      // vamos si selecciono alguna matriz
      const activematriz = formData.matrices_unidades.filter(
        (param) => param.activo === true,
      );
      if (activematriz.length === 0) {
        camposfaltan.push({ campo: i18n.t('ensayos.selectedmatriz') });
      }
      console.log({FORMDATAISCONTROLDATA:formData.data_ensayo.horas_incub})
      return camposfaltan;
    },
    async crearensayo() {
      // // console.log('entra a crear ensayo');
      // // console.log({ form: this.form_data });
      await Service.apiToken('POST', 'save-ensayos', this.$ls.storage.token, this.form_data);
      this.dialogensayo = false;
      this.loadingF = false;
      this.cancelForm();
    },
    async finalizar() {
      const E = false; // error
      this.overlay = true;
      this.overlay = false;
      return E;
    },
    cancelForm(clean = true) {
      if(clean){
        this.clearDataForm();
      }
      this.setSee(true);
    },
    clearDataForm() {
      this.form_data.editable = false;
      this.form_data.data_ensayo.tipoEnsa = null;
      this.form_data.data_ensayo.tipoMeto = null;
      this.form_data.data_ensayo.empresa = null;
      this.form_data.data_ensayo.ens_tipo = null;
      this.form_data.data_ensayo.descripcion = null;
      this.form_data.data_ensayo.metodologia = null;
      this.form_data.data_ensayo.numplacas = null;
      this.form_data.data_ensayo.parametrosid = [];
      this.form_data.paramtrosEnsayo = [];
      this.form_data.data_ensayo.tiempoenriquecimiento = null;
      this.form_data.data_ensayo.tiemporesult = null;
      this.form_data.data_ensayo.nombre = null;
      this.form_data.data_ensayo.tecnica = null;
      this.form_data.data_ensayo.productoid = null;
      this.form_data.data_ensayo.tipoRes = null;
      this.form_data.data_ensayo.sitioAnalisis = null;
      this.form_data.data_ensayo_original = null;
      this.form_data.data_ensayo.ensayoSelect = null;
      this.form_data.isNew = null;
      this.form_data.data_ensayo.mixes = null;
      this.form_data.nuevoensayo = false;
      this.form_data.parametrosedit = [];
      this.form_data.steriplex = false;
      this.form_data.data_ensayo.parametrosid = [];
      this.form_data.laboratorios = [];
      this.form_data.data_ensayo.cod_ensa = '';
      this.form_data.data_ensayo.costo = 0;
      this.form_data.data_ensayo.horas = 0;
      this.form_data.data_ensayo.activo = 0;
      
      this.form_data.data_umbrales.alimentosSelect = [{ id: 1, rsa_id: [], umbral: '' }];
      this.form_data.data_umbrales.superficiesSelect = [{ id: 1, rsa_id: [], umbral: '' }];
      this.form_data.data_umbrales.manipuldoresSelect = [{ id: 1, rsa_id: [], umbral: '' }];
      this.form_data.data_umbrales.aguasSelect = [{ id: 1, rsa_id: [], umbral: '' }];
      this.form_data.data_umbrales.ambientesSelect = [{ id: 1, rsa_id: [], umbral: '' }];

      const umbral = [{ id: 0, umbral: '', rsa_id: [] }];

      this.form_data.ambientesSelect = umbral;
      this.form_data.aguasSelect = umbral;
      this.form_data.manipuldoresSelect = umbral;
      this.form_data.superficiesSelect = umbral;
      this.form_data.alimentosSelect = umbral;
      this.form_data.data_ensayo.parametrosCondicion = [];
      this.form_data.data_ensayo.microbiologia = 0;

      this.form_data.matrices_unidades.map((currentValue, index) => {
        const curr = currentValue;
        this.form_data.matrices_unidades[index].activo = false;
        this.form_data.matrices_unidades[index].unidades = '';
        return curr;
      });
      this.setStep(1);
    },
    generateUmbral(umbral) {
      let umbralTxt = '';
      Object.entries(umbral).map((umb)=>{
        switch(umb[0]) {
          case 'nivel_1':
            umbralTxt+= 'L_'+umb[1];
            break;
          case 'nivel_2':
          case 'nivel_3':
          case 'nivel_4':
          case 'nivel_5':
          case 'nivel_6':
          case 'nivel_7':
            if(umb[1] >  0){
              umbralTxt+= '_'+umb[1];
            }
            break;
        }
        }
      );
      // // console.log('umbral dibujado',umbralTxt);
      return umbralTxt;
    },
    async setSee(value, testSelected = null, isNew = null) {
      //this.clearDataForm();

      // this.actionRangos([]);
      
      await this.getEnsainfo();
      this.clearDataForm();
      const testToEdit = (testSelected) ? await Service.apiToken('POST', 'get-test-id', this.$ls.storage.token, testSelected) : null;
      console.log('testToEdit', testToEdit);
      if (testToEdit && testToEdit.data) {
        const dataTest = testToEdit.data[0];
        // this.actionRangos(dataTest.rangosParametros);
        // console.log('dataTest', dataTest);
        
        const empresaProducto = dataTest.ensayos_empresas_productos[0] || [];
        const laboratorio = dataTest.ensayos_laboratorios[0] || [];
        const mix = dataTest.ensayos_has_mixs[0] || [];
        const codesPat = dataTest.patogeno_new_has_ensayos.map((pat) => pat.patogeno_new.cod_pat);
        // // console.log({codesPat:dataTest.patogeno_new_has_ensayos});
        this.form_data.paramtrosEnsayo = dataTest.patogeno_new_has_ensayos
        // si el ensayo no es del cliente logueado
        if (this.userInfo.user.id_planta !== dataTest.cliente_id) {
          // cambiamos a true para bloquear campos
          this.form_data.isComercial = true;
          this.form_data.editable = true; //se bloquea si es verdadero
        }

        //ASIGNAMOS LOS CONTROLES GUARDADOS
        this.form_data.data_ensayo.dataControl = dataTest.tb_r_control_ensayos;

        //--------------------------//-----------------------------//
        this.form_data.data_ensayo.cod_ensa = dataTest.cod_ensa;
        this.form_data.data_ensayo.tipoEnsa = dataTest.tipo_ensayo_id;
        this.form_data.data_ensayo.tipoMeto = (dataTest.comercial) ? 1 : 0;
        this.form_data.data_ensayo.empresa = empresaProducto.empresa_id || null;
        this.form_data.data_ensayo.ens_tipo = dataTest.d_tipo_de_ensayo;
        // console.log('aqio');
        this.form_data.data_ensayo.activo = (dataTest.ensayos_easypcrs.length > 0) ? dataTest.ensayos_easypcrs[0].activo : dataTest.activo;
        // this.form_data.data_ensayo.descripcion = dataTest.ensa_desc;
        // console.log('paso');
        this.form_data.data_ensayo.descripcion = (dataTest.ensayos_easypcrs.length > 0) ? dataTest.ensayos_easypcrs[0].descripcion : dataTest.ensa_desc;
        this.form_data.data_ensayo.metodologia = dataTest.metodologia;
        this.form_data.data_ensayo.horas = dataTest.horas;
        this.form_data.data_ensayo.horas_incub = dataTest.amt_horas_incubacion;
        this.form_data.data_ensayo.costo = dataTest.costo;
        this.form_data.data_ensayo.numplacas = dataTest.numero_placas;
        this.form_data.data_ensayo.parametrosid = codesPat;
        this.form_data.data_ensayo.condicionParam = dataTest.patogeno_new_has_ensayos;
        // this.form_data.data_ensayo.nombre = dataTest.ensa_informe;
        this.form_data.data_ensayo.nombre = (dataTest.ensayos_easypcrs.length > 0) ? dataTest.ensayos_easypcrs[0].name_ensayo : dataTest.ensa_informe;
        this.form_data.data_ensayo.tiempoenriquecimiento = dataTest.tiempo_incubacion;
        this.form_data.data_ensayo.tiemporesult = dataTest.dias;
        this.form_data.data_ensayo.tecnica = dataTest.tecnicas_id;
        this.form_data.data_ensayo.productoid = empresaProducto.producto_empresa_id || null;
        this.form_data.data_ensayo.tipoRes = dataTest.t_resultado;
        this.form_data.data_ensayo.sitioAnalisis = laboratorio.laboratorio_id || null;
        this.form_data.data_ensayo_original = dataTest.grupo_ensayo_id;
        this.form_data.data_ensayo.grupo_ensayo_id = dataTest.grupo_ensayo_id;

        this.form_data.data_ensayo.mixes = mix.mixs_id || null;
        this.form_data.nuevoensayo = true;
       
        this.data_ensayo.parametros.forEach((element) => {
        if (this.form_data.data_ensayo.parametrosid.includes(element.cod_pat)) {
          const itemElemet = element;
          const relfil = this.form_data.data_ensayo.condicionParam.filter(
            (param) => param.patogeno_new_cod_pat === itemElemet.cod_pat,
          );
          if (relfil.length > 0) {
            itemElemet.nivel = relfil[0].nivel;
            itemElemet.clasificacionid = relfil[0].clasificacion;
          }
          this.form_data.data_ensayo.parametrosCondicion.push(itemElemet);
        }
      });
       // laboratorios
        dataTest.ensayos_laboratorios.forEach((element) => {
          this.form_data.laboratorios.push(element.laboratorio_id);
        });
        // // console.log('lab', this.form_data.laboratorios);
        // this.form_data.data_ensayo.microbiologia =
        const items = this.data_ensayo.tipo_ensayos;
        const res = items.filter((tipo) => tipo.id_tipo_de_ensayo === dataTest.tipo_ensayo_id);
        if (res.length > 0 && res[0].inlaca_tipo_ensayo.MT === 0) {
          this.tipoEnsasel(0);
        } else {
          this.tipoEnsasel(1);
        }

        // recorremos patogeno new has ensayo
        dataTest.patogeno_new_has_ensayos.forEach((element) => {
          element.nombre = element.patogeno_new.nombre;
          element.peligrosidadID = element.id_nivel_peligro;
          element.nivel = element.nivel;
          element.clasificacionid = element.clasificacion;
        });
        this.form_data.parametrosedit = dataTest.patogeno_new_has_ensayos;
        // asignamos si es un ensayo steriplex o no
        this.form_data.steriplex = (dataTest.steriplex === 1);
        this.form_data.kitsteriplex = dataTest.kit_steriplex;
        this.form_data.clasificacion = dataTest.clasificacion;
        testToEdit.data.forEach((test) => {
          console.log({test});
          const matrixObject = this.form_data.matrices_unidades;
          // // console.log(matrixObject);
          const matrixFound = matrixObject.findIndex((element) => element.id === test.matrices_id);
          let activo = parseInt(test.activo);
          this.form_data.matrices_unidades[matrixFound].activo = (test.ensayos_easypcrs.length > 0 && test.ensayos_easypcrs[0].activo ) ? true : activo;//false;
          this.form_data.matrices_unidades[matrixFound].unidades = test.unidades;
          let umbral = [];
          const arrayMatriz = [12, 9, 7, 8, 6];
          let contindex = 0;
          const groups = _.groupBy(test.umbrales, 'grupoid');//_.groupBy(test.umbrales, 'unidades');
          /// /agregamos nuevo
          Object.keys(groups).map((group) => {
            const agrupaumbral = _.groupBy(groups[group], 'grupoid');
            Object.keys(agrupaumbral).map((groupumbral) => {
              if (arrayMatriz.includes(groups[group][0].matriz_id)) {
                umbral.push({
                  id: contindex,
                  umbral: agrupaumbral[groupumbral][0].umbral,
                  unidades: agrupaumbral[groupumbral][0].unidades,
                  parametrosubral: this.filtraparametroUmbral(agrupaumbral[groupumbral][0].umbral_parametros,dataTest.patogeno_new_has_ensayos),
                  rsa_id: agrupaumbral[groupumbral].map((umb) => (
                    //umb.subrsa_id === 0 ? `${umb.rsa_id}_R` : `${umb.subrsa_id}_S_${umb.rsa_id}`
                    this.generateUmbral(umb)
                    )
                  ),
                });
              } else {
                umbral.push({
                  id: contindex,
                  umbral: agrupaumbral[groupumbral][0].umbral,
                  rsa_id: agrupaumbral[groupumbral].map((umb) => umb.rsa_id),
                  unidades: agrupaumbral[groupumbral][0].unidades,
                });
              }
              contindex += 1;
              return true;
            });
            return true;
          });
          // // console.log('umbrales', umbral);
          /// fin de nuevo
         
          const noUmbral = [{ id: 0, umbral: '', rsa_id: [] }];
          umbral = (umbral.length > 0) ? umbral : noUmbral;
          // // console.log('miumbral', umbral);
          switch (test.matrices_id) {
            case 6: this.form_data.data_umbrales.ambientesSelect = umbral;

            break;
            case 7: this.form_data.data_umbrales.aguasSelect = umbral;
            break;
            case 8: this.form_data.data_umbrales.manipuldoresSelect = umbral;
            break;
            case 9:

              this.form_data.data_umbrales.superficiesSelect = umbral;
            break;
            case 12: this.form_data.data_umbrales.alimentosSelect = umbral;
            break;
            default: break;
          }
          return true;
        });
      }
      // console.warn(this.rangos);
      // // console.log('matricesunidades', this.form_data.matrices_unidades);
      this.form_data.isNew = isNew;
      // this.seeTable = value;
      this.$store.state.seeTable = value;
    },
    selectedTipores(event) {
      if (event === true) {
        this.form_data.data_ensayo.tipoRes = 8;
      } else {
        this.form_data.kitsteriplex = 0;
        this.form_data.data_ensayo.tipoRes = null;
      }
    },
    aceptarModal() {
      this.dialogensayo = false;
    },
    async getEnsainfo() {
      const data = await Service.apiToken('POST', 'ensayos', this.$ls.storage.token, { });
      this.datosEnsayo(data);
      const datosUser = await Service.apiToken('POST', 'get-userinfo', this.$ls.storage.token, { });
      this.datosUser(datosUser);
    },
    async umbralConfig(dataumbrales) {
      const aguaumbral = dataumbrales.aguasSelect;
      aguaumbral.forEach((element) => {
        element.umbral = 0;
      });

      const alimentoumbral = dataumbrales.alimentosSelect;
      alimentoumbral.forEach((element) => {
        element.umbral = 0;
      });
      const ambienteumbral = dataumbrales.ambientesSelect;
      ambienteumbral.forEach((element) => {
        element.umbral = 0;
      });
      const manipuladorumbral = dataumbrales.manipuldoresSelect;
      manipuladorumbral.forEach((element) => {
        element.umbral = 0;
      });
      const superficieumbral = dataumbrales.superficiesSelect;
      superficieumbral.forEach((element) => {
        element.umbral = 0;
      });
      dataumbrales.aguasSelect = aguaumbral;
      dataumbrales.alimentosSelect = alimentoumbral;
      dataumbrales.ambientesSelect = ambienteumbral;
      dataumbrales.manipuldoresSelect = manipuladorumbral;
      dataumbrales.superficiesSelect = superficieumbral;
      return dataumbrales;
    },
    async validarUmbrales(dataensayos){
      let Umbrales = [];
      dataensayos.matrices_unidades.forEach(element => {
        if(element.activo == true){
          switch (element.id) {
            case 12:
              if(dataensayos.data_umbrales.alimentosSelect.length == 0){
                Umbrales.push({ campo: i18n.t('ensayos.umbralesalimento') })
              }
              break;
            case 9:
              if(dataensayos.data_umbrales.superficiesSelect.length == 0){
                Umbrales.push({ campo: i18n.t('ensayos.umbralessuperficie') })
              }
              break;
            case 8:
              if(dataensayos.data_umbrales.manipuldoresSelect.length == 0){
                Umbrales.push({ campo: i18n.t('ensayos.umbralesmanipuladores') })
              }
              break;
            case 7:
              if(dataensayos.data_umbrales.aguasSelect.length == 0){
                Umbrales.push({ campo: i18n.t('ensayos.umbralesagua') })
              }
              break;
            case 6:
              if(dataensayos.data_umbrales.ambientesSelect.length == 0){
                Umbrales.push({ campo: i18n.t('ensayos.umbralesambiente') })
              }
              break;
          }
        }        
      });

      return Umbrales;
    },
    async continuarGuardado(){
      this.dialogstop = false;
      await Service.apiToken('POST', 'save-ensayos', this.$ls.storage.token, this.formSend);
      setTimeout(this.despuesEnsayo(), 3000);
    },
    filtraparametroUmbral(configumbral,parametrosEnsayo){
      console.warn('parametros ensayo', parametrosEnsayo);
      configumbral.forEach(element => {
        console.warn('config umbral', element);
        let resultParam = parametrosEnsayo.filter(tipoparam =>{console.log('comparacion', {tipoparam: tipoparam, element: element.parametro_id}) ;return tipoparam.patogeno_new_cod_pat == element.parametro_id});
        if(resultParam.length > 0){
          console.warn('result param', resultParam);
          element.nombre = resultParam[0].nombre;
        }
      });
      return configumbral;
    }
  },
  async created() {
    // // console.log('creando el ensayo');
    const data = await Service.apiToken('POST', 'ensayos', this.$ls.storage.token, { });
    this.datosEnsayo(data);
    const datosUser = await Service.apiToken('POST', 'get-userinfo', this.$ls.storage.token, { });
    this.datosUser(datosUser);
    
    
  },

  
};
</script>
